import escapeHtml from "escape-html";
import { Text } from "slate";
import { renderToStaticMarkup } from "react-dom/server";
import { getBlock, getMarked } from "./SlateUtilityFunctions.js";

const unescapeHTML = (str) =>
  str.replace(
    /&amp;|&lt;|&gt;|&#39;|&quot;/g,
    (tag) =>
      ({
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&#39;": "'",
        "&quot;": '"',
      }[tag] || tag)
  );
export const serialize = (node, caller) => {
  if (Text.isText(node)) {
    let string = getMarked(node, node.text);

    string = renderToStaticMarkup(string);

    return string;
  }

  const children = node.children?.map((n) => serialize(n, caller)).join("");
  let block = getBlock({ children, element: node, caller: caller });

  block = renderToStaticMarkup(block);

  return unescapeHTML(block);
};

export const serializer = (editorValue, caller) => {
  if (editorValue !== undefined && editorValue?.length > 0) {
    return editorValue?.map((n) => serialize(n, caller)).join("");
  }
};
